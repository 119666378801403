export const MaintenanceRouter = [
  {
    path: "/maintenance",
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "maintenance" */ "./components/maintenance-view.vue"
      )
  }
];

import Vue from "vue";
import Router from "vue-router";
import { MaintenanceRouter } from "./maintenance/maintenance.router";
import { PaymentRouter } from "./payment/payment.router";

Vue.use(Router);

const router = new Router({
  base: process.env.BASE_URL,
  mode: "history",
  routes: [...MaintenanceRouter, ...PaymentRouter]
});

export default router;

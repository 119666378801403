



































































































import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
  name: "error-message",
  components: {}
})
export default class ErrorMessage extends Vue {
  @Prop({ default: false }) readonly loading!: boolean;
}













































import { Component, Vue } from "vue-property-decorator";
import AfterpayLogo from "../../common/components/afterpay-logo.vue";
import AfterpayButton from "./afterpay-button.vue";
import AfterpayPaymentSteps from "./afterpay-payment-steps.vue";
import TermsAndConditionsButton from "./terms-and-conditions-button.vue";
import ErrorMessage from "./error-message.vue";

@Component({
  name: "payment-view",
  components: {
    AfterpayLogo,
    SuccessfulOverlay: () =>
      import(
        /* webpackChunkName: "successful-overlay" */ "./successful-overlay.vue"
      ),
    AfterpayPaymentSteps,
    TermsAndConditionsButton,
    ErrorMessage,
    AfterpayButton
  }
})
export default class PaymentView extends Vue {
  $afterpayPopupWindow: any;
  $afterpayWidget: any;
  $payButton: any;
  $checkbox: any;
  $errorMessage: any;
  afterpayConfiguration = null;
  showSuccessfulOverlay = false;
  showPayButtonLoader = false;
  showConfigurationError = false;
  showPaymentError = false;
  errorMessage = "";
  defaultErrorMessage = "An error occurred please try again later";
  errors = {
    minimumAmount:
      "Payment Failed: Your Purchase must be at least [AMOUNT] [CURRENCY] to pay with Afterpay.",
    maximumAmount:
      "Payment Failed: Your Purchase must be less than [AMOUNT] [CURRENCY] to pay with Afterpay."
  };
  widgetHeights = {
    COLLAPSED: 55
  };

  async initWidget() {
    this.setIframeHeight(this.widgetHeights.COLLAPSED);
    try {
      this.afterpayConfiguration = await this.getAfterpayConfiguration();
      this.initializeErrorMessages(this.afterpayConfiguration);
    } catch (error) {
      this.showConfigurationError = true;
    }

    this.$payButton?.addEventListener("click", async (event: any) => {
      try {
        const AfterPay: any = (window as any | Window).AfterPay;
        this.addLoaderToPayButton();
        this.disableClickOnPayButton();
        AfterPay.initialize({ countryCode: "US" });
        this.$afterpayPopupWindow = AfterPay.open();
        await this.payWithAfterpay();
        if (!this.showSuccessfulOverlay) {
          this.showDoneOverlay();
          this.showPaymentError = false;
        } else {
          this.hideDoneOverlay();
        }
        this.startListeningOrderChanges();
      } catch (error) {
        if (!this.$afterpayPopupWindow.closed) {
          this.$afterpayPopupWindow.close();
        }
        this.showErrorMessage(error);
      } finally {
        this.enableClickOnPayButton();
        this.removeLoaderToPayButton();
      }
    });

    this.$checkbox!.addEventListener("change", (event: any) => {
      if ((this.$checkbox as any).checked) {
        this.expandWidget();
      } else {
        this.collapseWidget();
      }
    });

    window.addEventListener("resize", this.handleResize);
  }
  expandWidget() {
    this.$afterpayWidget!.classList.add("afterpay--expanded");
    this.setIframeHeight(document.documentElement.offsetHeight);
  }
  collapseWidget() {
    this.$afterpayWidget!.classList.remove("afterpay--expanded");
    this.setIframeHeight(this.widgetHeights.COLLAPSED);
  }

  initializeErrorMessages(afterpayConfiguration: any) {
    this.errors.minimumAmount = this.errors.minimumAmount.replace(
      "[AMOUNT]",
      afterpayConfiguration.minimumAmount.amount
    );
    this.errors.minimumAmount = this.errors.minimumAmount.replace(
      "[CURRENCY]",
      afterpayConfiguration.minimumAmount.currency
    );
    this.errors.maximumAmount = this.errors.maximumAmount.replace(
      "[AMOUNT]",
      afterpayConfiguration.maximumAmount.amount
    );
    this.errors.maximumAmount = this.errors.maximumAmount.replace(
      "[CURRENCY]",
      afterpayConfiguration.maximumAmount.currency
    );
  }
  async payWithAfterpay() {
    const order = (await this.requestOrderToBold()) as any;
    const afterpayPaymentValidation = this.checkIfAfterpayIsValid(
      order,
      this.afterpayConfiguration
    );
    if (!afterpayPaymentValidation.isValid) {
      throw new Error(afterpayPaymentValidation.errorMessage);
    } else {
      this.hideErrorMessage();
      await this.startPayment(order);
    }
  }
  requestOrderToBold() {
    return new Promise(resolve => {
      function receiveOrderListener(event: any) {
        const message = event.data;
        switch (message.type) {
          case "checkout/receive_order":
            resolve(message.payload);
            window.removeEventListener("message", receiveOrderListener);
            break;
        }
      }
      window.addEventListener("message", receiveOrderListener);
      parent.postMessage(
        {
          type: "checkout/request_order"
        },
        "*"
      );
    });
  }
  checkIfAfterpayIsValid(
    order: any,
    configuration: any
  ): { isValid: boolean; errorMessage: string } {
    let isValid = false;
    let errorMessage = "";
    const hasValidCurrency =
      order.currency === configuration.minimumAmount.currency &&
      order.currency === configuration.maximumAmount.currency;
    const amountHigherThanMinimum =
      order.total_remaining >=
      this.formatToBoldAmount(configuration.minimumAmount.amount);
    const amountBelowMaximum =
      order.total_remaining <=
      this.formatToBoldAmount(configuration.maximumAmount.amount);
    const hasValidAmount = amountHigherThanMinimum && amountBelowMaximum;

    if (!amountHigherThanMinimum) {
      errorMessage = this.errors.minimumAmount;
    }
    if (!amountBelowMaximum) {
      errorMessage = this.errors.maximumAmount;
    }

    if (hasValidAmount && hasValidCurrency) {
      isValid = true;
    }
    return { isValid, errorMessage };
  }

  async startPayment(order: any) {
    const afterpayToken = await this.getAfterpayToken(order);
    await this.startAfterpayPopupFlow(afterpayToken.token, order);
  }

  getAfterpayToken(order: any) {
    const url = `${process.env.VUE_APP_API_PROTOCOL}${process.env.VUE_APP_API_DOMAIN}/payment/checkout`;
    return fetch(url, {
      method: "POST",
      body: JSON.stringify({ order: order }),
      headers: {
        "Content-Type": "application/json"
      }
    }).then(response => {
      const contentType = response.headers.get("content-type");
      if (!contentType || !contentType.includes("application/json")) {
        throw new TypeError("Oops, we haven't got JSON!");
      }

      if (response.status !== 201) {
        throw new Error(this.defaultErrorMessage);
      }
      return response.json();
    });
  }
  getAfterpayConfiguration() {
    const url = `${process.env.VUE_APP_API_PROTOCOL}${process.env.VUE_APP_API_DOMAIN}/payment/configuration`;
    return fetch(url, {
      method: "GET"
    }).then(response => {
      const contentType = response.headers.get("content-type");
      if (!contentType || !contentType.includes("application/json")) {
        throw new TypeError("Oops, we haven't got JSON!");
      }
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      return response.json();
    });
  }
  addAfterpayPayment(orderToken: string, order: any) {
    parent.postMessage(
      {
        type: "checkout/app_hook",
        payload: {
          hook: "app_hook",
          data: { orderRemaining: order.total_remaining, token: orderToken }
        }
      },
      "*"
    );
  }
  formatToBoldAmount(amount: string) {
    return parseFloat(amount) * 100;
  }
  setIframeHeight(height: number) {
    parent.postMessage(
      {
        type: "checkout/resize_frame",
        payload: { height: height }
      },
      "*"
    );
  }
  startAfterpayPopupFlow(incomingToken: string, order: any) {
    return new Promise((resolve, reject) => {
      const AfterPay: any = (window as any | Window).AfterPay;
      // If you don't already have a checkout token at this point, you can
      // AJAX to your backend to retrieve one here. The spinning animation
      // will continue until `AfterPay.transfer` is called.
      AfterPay.onComplete = (event: any) => {
        if (event.data.status == "SUCCESS") {
          // The consumer confirmed the payment schedule.
          // The token is now ready to be captured from your server backend.
          const orderToken = event.data.orderToken;
          this.addAfterpayPayment(orderToken, order);
          resolve(order);
        } else {
          reject(
            "The consumer cancelled the payment or closed the popup window."
          );
        }
      };
      AfterPay.transfer({ token: incomingToken });
    });
  }

  startListeningOrderChanges() {
    const intervalId = setInterval(async () => {
      const order = (await this.requestOrderToBold()) as any;
      if (order.total_remaining !== 0) {
        this.hideDoneOverlay();
        clearInterval(intervalId);
      }
    }, 3000);
  }

  addLoaderToPayButton() {
    this.showPayButtonLoader = true;
  }
  removeLoaderToPayButton() {
    this.showPayButtonLoader = false;
  }
  disableClickOnPayButton() {
    this.$payButton?.setAttribute("disabled", "true");
  }
  enableClickOnPayButton() {
    this.$payButton?.removeAttribute("disabled");
  }
  showErrorMessage(message: string) {
    this.errorMessage = message;
    this.showPaymentError = true;
    this.$nextTick(() => {
      this.setIframeHeight(document.documentElement.offsetHeight);
    });
  }
  hideErrorMessage() {
    this.showPaymentError = false;
    this.$nextTick(() => {
      this.setIframeHeight(document.documentElement.offsetHeight);
    });
  }
  showDoneOverlay() {
    this.showSuccessfulOverlay = true;
  }
  hideDoneOverlay() {
    this.showSuccessfulOverlay = false;
  }

  hideInitialLoader() {
    document.getElementById("initial-loader")!.style.display = "none";
  }

  get documentHeight() {
    return document.documentElement.offsetHeight;
  }

  handleResize() {
    const targetHeight = this.$afterpayWidget!.classList.contains(
      "afterpay--expanded"
    )
      ? document.documentElement.offsetHeight
      : this.widgetHeights.COLLAPSED;
    this.setIframeHeight(targetHeight);
  }

  async mounted() {
    this.$afterpayWidget = document.getElementById("afterpay-widget");
    this.$payButton = document.getElementById("afterpay-pay-button");
    this.$checkbox = document.getElementById("afterpay-checkbox");
    await this.initWidget();
    this.hideInitialLoader();
  }
}
